import React from 'react';
import { Link } from 'react-router-dom';

import { Topbar, TopbarNav, TopbarNavItem, TopbarLogo } from '../shared/Topbar';
import { ToggleContainer } from '../shared/ToggleContainer';

import { ReactComponent as Logo } from '../../assets/icons/fy-logo.svg';
import { ReactComponent as DayIcon } from '../../assets/icons/day-icon.svg';
import { ReactComponent as NightIcon } from '../../assets/icons/night-icon.svg';

export default function Navbar({ theme, toggleTheme }) {
    const isLight = theme === 'light';

    return (
        <Topbar role="navigation">
            <Link to="/">
                <TopbarLogo width="32px" height="58px">
                    <Logo alt="Fredrik Yumo Logo link to Home Page" />
                </TopbarLogo>
            </Link>
            <TopbarNav>
                <TopbarNavItem as={Link} to="/">Projects</TopbarNavItem>
                <TopbarNavItem as={Link} to="/about">About</TopbarNavItem>
                {/* <TopbarNavItem as={Link} to="/contact">Contact</TopbarNavItem> */}
                <ToggleContainer lightTheme={isLight} onClick={toggleTheme}>
                    <DayIcon />
                    <NightIcon />
                </ToggleContainer>
            </TopbarNav>
        </Topbar>
    );
}