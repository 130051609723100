import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './components/GlobalStyles';
import { lightTheme, darkTheme } from './components/Theme';
import { useDarkMode } from './hooks/useDarkMode';
import ScrollToTop from './hooks/ScrollToTop';

import { Wrapper } from './components/layout/Wrapper';

import Navbar from './components/features/Navbar';
import CustomFooter from './components/features/CustomFooter';
import Home from './pages/Home';
import About from './pages/About';
import RollApp from './pages/RollApp';
import Wavelength from './pages/Wavelength';
import Fluently from './pages/Fluently';
import TheEggAndUs from './pages/TheEggAndUs';
import Travlr from './pages/Travlr';
import AlchemyUI from './pages/AlchemyUI';

export default function App() {
  const [theme, toggleTheme, componentMounted] = useDarkMode();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />
  }

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <ScrollToTop />
        <Navbar theme={theme} toggleTheme={toggleTheme} />
        <Wrapper>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/projects/roll" component={RollApp} />
            <Route path="/projects/wavelength" component={Wavelength} />
            <Route path="/projects/fluently" component={Fluently} />
            <Route path="/projects/the-egg-and-us" component={TheEggAndUs} />
            <Route path="/projects/travlr" component={Travlr} />
            <Route path="/projects/alchemy-ui" component={AlchemyUI} />
          </Switch>
        </Wrapper>
        <CustomFooter />
      </>
    </ThemeProvider>
  );
}